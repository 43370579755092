define('tfcash/routes/login', ['exports', 'ember', 'tfcash/mixins/authenticated-redirect-home'], function (exports, _ember, _tfcashMixinsAuthenticatedRedirectHome) {
  exports['default'] = _ember['default'].Route.extend(_tfcashMixinsAuthenticatedRedirectHome['default'], {
    model: function model() {
      return {
        email: '',
        password: ''
      };
    },

    actions: {
      signIn: function signIn(model) {
        var _this = this;

        this.get('session').open('firebase', {
          provider: 'password',
          email: model.email,
          password: model.password
        }).then(function (data) {
          console.log(data.currentUser);
          _this.transitionTo('dashboard.index');
        })['catch'](function (error) {
          alert(error.message);
        });
      }
    }
  });
});