define('tfcash/helpers/range', ['exports', 'ember'], function (exports, _ember) {
  exports.range = range;

  function range(params /*, hash*/) {
    var range = [];
    var i = params[0];
    for (i; i < params[1]; ++i) {
      range.push(i);
    }
    return range;
  }

  exports['default'] = _ember['default'].Helper.helper(range);
});