define('tfcash/routes/dashboard/accounts/view', ['exports', 'ember', 'rsvp'], function (exports, _ember, _rsvp) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var splits = this.store.query('split', {
        orderBy: 'account',
        equalTo: params.account_id
      }).then(function (splits) {
        return _rsvp['default'].all(splits.getEach('transaction')).then(function (transactions) {
          splits.forEach(function (split, i) {
            split.set('transaction', transactions[i]);
          });
          return splits;
        });

        //return RSVP.all(splits.getEach('transaction')).then((resolvedTransactions) => {
        //  transactions.addObjects(resolvedTransactions);
        //  return splits;
        //});
      });

      return _rsvp['default'].hash({
        account: this.store.findRecord('account', params.account_id),
        accounts: this.store.findAll('account'),
        splits: splits
      });

      //this.store.query('split', {
      //  orderBy: 'account',
      //  equalTo: params.account_id
      //}).then((splits) => {
      //  splits.forEach((split) => {
      //    return split.
      //  });
      //});
      //
      //return RSVP.hash({
      //  account: this.store.findRecord('account', params.account_id),
      //  accounts: this.store.findAll('account'),
      //  splits: this.store.query('split', {
      //    orderBy: 'account',
      //    equalTo: params.account_id
      //  })//,
      //  //transactions: this.store.findAll('transaction'),
      //  //transaction: {}
      //});
    },

    afterModel: function afterModel(model) {
      console.log(model);
    }

    //afterModel(model) {
    //  model.accounts = model.accounts.filter(function(account) {
    //    return account && account.get('id') !== model.account.get('id');
    //  });
    //
    //  model.transactions = model.transactions.filter(function(transaction) {
    //    return transaction.get('from').get('id') === model.account.get('id') || transaction.get('to').get('id') === model.account.get('id');
    //  });
    //
    //  let balance = 0;
    //  model.transactions.forEach(function(transaction) {
    //    if (model.account.get('type') === 'equity' || model.account.get('type') === 'income' || model.account.get('type') === 'liability') {
    //      if (transaction.get('from').get('id') === model.account.get('id')) {
    //        transaction.set('transfer', transaction.get('from'));
    //        transaction.set('add', transaction.get('amount'));
    //        transaction.set('subtract', undefined);
    //      } else {
    //        transaction.set('transfer', transaction.get('to'));
    //        transaction.set('subtract', transaction.get('amount'));
    //        transaction.set('add', undefined);
    //      }
    //    } else if (model.account.get('type') === 'expense' || model.account.get('type') === 'asset') {
    //      if (transaction.get('from').get('id') === model.account.get('id')) {
    //        transaction.set('transfer', transaction.get('to'));
    //        transaction.set('subtract', transaction.get('amount'));
    //        transaction.set('add', undefined);
    //      } else {
    //        transaction.set('transfer', transaction.get('from'));
    //        transaction.set('add', transaction.get('amount'));
    //        transaction.set('subtract', undefined);
    //      }
    //    }
    //
    //    balance = balance + (transaction.get('add') || 0) - (transaction.get('subtract') || 0);
    //    transaction.set('balance', balance);
    //  });
    //},

    //actions: {
    //  addTransaction(data) {
    //    // ToDo: not working!!
    //    let transactionObj = {
    //      date: new Date(),
    //      description: data.description,
    //      amount: Math.max(data.add || 0, data.subtract || 0)
    //    };
    //
    //    if (data.add) {
    //      transactionObj.to = this.context.account;
    //      transactionObj.from = data.transfer;
    //    } else {
    //      transactionObj.to = data.transfer;
    //      transactionObj.from = this.context.account;
    //    }
    //
    //    let transaction = this.store.createRecord('transaction', transactionObj);
    //    transaction.save();
    //  }
    //}
  });
});