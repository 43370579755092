define('tfcash/routes/dashboard/accounts/new', ['exports', 'ember', 'rsvp', 'tfcash/utils/account'], function (exports, _ember, _rsvp, _tfcashUtilsAccount) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return _rsvp['default'].hash({
        account: {},
        accounts: this.store.findAll('account'),
        accountTypes: _tfcashUtilsAccount['default'].TYPES
      });
    },

    actions: {
      create: function create(data) {
        var _this = this;

        var account = this.store.createRecord('account', data);
        account.save().then(function () {
          _this.transitionTo('dashboard.accounts.index');
        })['catch'](function () {
          account.rollbackAttributes();
          alert('error!!');
        });
      }
    }
  });
});