define('tfcash/routes/dashboard/accounts/edit', ['exports', 'ember', 'rsvp', 'tfcash/utils/account'], function (exports, _ember, _rsvp, _tfcashUtilsAccount) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      return _rsvp['default'].hash({
        account: this.store.findRecord('account', params.account_id),
        accounts: this.store.findAll('account'),
        accountTypes: _tfcashUtilsAccount['default'].TYPES
      });
    },

    actions: {
      create: function create(account) {
        var _this = this;

        account.save().then(function () {
          _this.transitionTo('dashboard.accounts.index');
        })['catch'](function () {
          account.rollbackAttributes();
          alert('error!!');
        });
      }
    }
  });
});