define('tfcash/routes/application', ['exports', 'ember'], function (exports, _ember) {

  _ember['default'].Route.reopen({
    activate: function activate() {
      var cssClass = this.toCssClass();
      if (cssClass !== 'route-application') {
        _ember['default'].$('body').addClass(cssClass);
      }
    },
    deactivate: function deactivate() {
      _ember['default'].$('body').removeClass(this.toCssClass());
    },
    toCssClass: function toCssClass() {
      var css = this.routeName.replace(/\./g, '-').dasherize();
      return 'route-' + css;
    }
  });

  exports['default'] = _ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      return this.get("session").fetch()['catch'](function () {});
    }
  });
});