define('tfcash/routes/dashboard', ['exports', 'ember'], function (exports, _ember) {
  //import GuestRedirectLogin from 'tfcash/mixins/guest-redirect-login';

  exports['default'] = _ember['default'].Route.extend( /*GuestRedirectLogin, */{
    actions: {
      signOut: function signOut() {
        this.get("session").close();
        this.transitionTo('login');
      }
    }
  });
});