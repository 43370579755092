define('tfcash/router', ['exports', 'ember', 'tfcash/config/environment'], function (exports, _ember, _tfcashConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _tfcashConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('dashboard', { path: '/' }, function () {
      this.route('index', { path: '/' });
      this.route('accounts', function () {
        this.route('new');
        this.route('edit', { path: '/edit/:account_id' });
        this.route('view', { path: '/:account_id' });
      });
      this.route('reset');
    });
    this.route('login');
  });

  exports['default'] = Router;
});