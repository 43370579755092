define('tfcash/models/account', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({
    name: (0, _emberDataAttr['default'])('string'),
    description: (0, _emberDataAttr['default'])('string'),
    type: (0, _emberDataAttr['default'])('string'),
    parent: (0, _emberDataRelationships.belongsTo)('account', { inverse: null }),
    level: Ember.computed('parent', function () {
      var level = 0;
      var parent = this.get('parent');
      while (parent && parent.get('id')) {
        parent = parent.get('parent');
        level++;
      }
      return level;
    })
  });
});