define('tfcash/components/dashboard/accounts-list', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    accounts: [],

    sortedAccounts: _ember['default'].computed('accounts', function () {
      var accounts = this.get('accounts').sortBy('name');

      function sortBy(accounts, parentId, newAccounts) {
        if (newAccounts && !parentId) {
          return newAccounts;
        }

        newAccounts = newAccounts || [];

        var i = 0;
        var size = accounts.length;
        var account = undefined;
        for (i; i < size; i++) {
          account = accounts[i];
          var _parent = account.get('parent');

          if (_parent && _parent.get('id') === parentId) {
            newAccounts.push(account);

            newAccounts = sortBy(accounts, account.get('id'), newAccounts);
          }
        }

        return newAccounts;
      }

      return sortBy(accounts);
    })
  });
});