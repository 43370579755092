define('tfcash/utils/account', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.create({
    TYPES: [{
      key: 'asset',
      text: 'Asset'
    }, {
      key: 'equity',
      text: 'Equity'
    }, {
      key: 'expense',
      text: 'Expense'
    }, {
      key: 'income',
      text: 'Income'
    }, {
      key: 'liability',
      text: 'Liability'
    }]
  });
});