define('tfcash/routes/dashboard/reset', ['exports', 'ember', 'rsvp'], function (exports, _ember, _rsvp) {
  exports['default'] = _ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      if (!confirm('Are you sure you want to reset?')) {
        this.transitionTo('dashboard.index');
      }
    },
    model: function model() {
      var transactions = this.store.findAll('transaction');
      var splits = this.store.findAll('split');
      var accounts = this.store.findAll('account');

      return _rsvp['default'].hash({
        splits: splits,
        transactions: transactions,
        accounts: accounts
      });
    },
    afterModel: function afterModel(model) {
      this._destroy(model);
      this._create();
    },

    _destroy: function _destroy(model) {
      if (model && model.splits) {
        model.splits.forEach(function (split) {
          split.destroyRecord();
        });
      }

      if (model && model.transactions) {
        model.transactions.forEach(function (transaction) {
          transaction.destroyRecord();
        });
      }

      if (model && model.accounts) {
        model.accounts.forEach(function (account) {
          account.destroyRecord();
        });
      }
    },

    _create: function _create() {
      // CREATING BASE
      // ------------------------------------------------------------------
      var asset = this.store.createRecord('account', {
        name: 'Asset / Ativos',
        description: 'Asset / Ativos',
        type: 'asset'
      });
      asset.save();

      var equity = this.store.createRecord('account', {
        name: 'Equity / Equidade',
        description: 'Equity / Equidade',
        type: 'equity'
      });
      equity.save();

      var expense = this.store.createRecord('account', {
        name: 'Expenses / Despesas',
        description: 'Expenses / Despesas',
        type: 'expense'
      });
      expense.save();

      var income = this.store.createRecord('account', {
        name: 'Income / Receitas',
        description: 'Income / Receitas',
        type: 'income'
      });
      income.save();

      var liability = this.store.createRecord('account', {
        name: 'Liabilities / Passivos',
        description: 'Liabilities / Passivos',
        type: 'liability'
      });
      liability.save();

      // CREATING ASSETS
      // ------------------------------------------------------------------
      var cashInWallet = this.store.createRecord('account', {
        name: 'Cash in wallet',
        description: 'Cash in wallet',
        type: 'asset',
        parent: asset
      });
      cashInWallet.save();

      var chaseCheckingsAccount = this.store.createRecord('account', {
        name: 'Chase - Checkings',
        description: 'Chase - Checkings account',
        type: 'asset',
        parent: asset
      });
      chaseCheckingsAccount.save();

      var chaseSavingsAccount = this.store.createRecord('account', {
        name: 'Chase - Savings',
        description: 'Chase - Savings account',
        type: 'asset',
        parent: asset
      });
      chaseSavingsAccount.save();

      // CREATING EQUITY
      // ------------------------------------------------------------------
      var openingBalances = this.store.createRecord('account', {
        name: 'Opening Balances',
        description: 'Opening Balances',
        type: 'equity',
        parent: equity
      });
      openingBalances.save();

      // CREATING EXPENSES
      // ------------------------------------------------------------------
      var restaurants = this.store.createRecord('account', {
        name: 'Restaurants',
        description: 'Restaurants',
        type: 'expense',
        parent: expense
      });
      restaurants.save();

      var insurance = this.store.createRecord('account', {
        name: 'Insurance',
        description: 'Insurance',
        type: 'expense',
        parent: expense
      });
      insurance.save();

      var healthInsurance = this.store.createRecord('account', {
        name: 'Health Insurance',
        description: 'Health Insurance',
        type: 'expense',
        parent: insurance
      });
      healthInsurance.save();

      var lifeInsurance = this.store.createRecord('account', {
        name: 'Life Insurance',
        description: 'Life Insurance',
        type: 'expense',
        parent: insurance
      });
      lifeInsurance.save();

      // CREATING INCOME
      // ------------------------------------------------------------------
      var salary = this.store.createRecord('account', {
        name: 'Salary',
        description: 'Salary',
        type: 'income',
        parent: income
      });
      salary.save();

      var interestIncome = this.store.createRecord('account', {
        name: 'Interest Income',
        description: 'Interest Income',
        type: 'income',
        parent: income
      });
      interestIncome.save();

      var checkingInterest = this.store.createRecord('account', {
        name: 'Checking Interest',
        description: 'Checking Interest',
        type: 'income',
        parent: interestIncome
      });
      checkingInterest.save();

      var savingsInterest = this.store.createRecord('account', {
        name: 'Savings Interest',
        description: 'Savings Interest',
        type: 'income',
        parent: interestIncome
      });
      savingsInterest.save();

      // CREATING INCOME
      // ------------------------------------------------------------------
      var wellsFargoSecuredCard = this.store.createRecord('account', {
        name: 'Wells Fargo Secured Card',
        description: 'Wells Fargo Secured Card',
        type: 'liability',
        parent: liability
      });
      wellsFargoSecuredCard.save();

      // CREATING TRANSACTIONS
      // ------------------------------------------------------------------

      // Opening balance
      this._addTransaction(openingBalances, cashInWallet, 'Opening value', 23.30);
      this._addTransaction(openingBalances, chaseCheckingsAccount, 'Opening value', 14045.30);

      // Adding salary
      this._addTransaction(salary, chaseCheckingsAccount, 'Salary', 2000);

      // Adding expenses
      this._addTransaction(chaseCheckingsAccount, restaurants, 'Gots', 29);
      this._addTransaction(chaseCheckingsAccount, restaurants, 'Caputo', 134);

      // Adding credit card expenses
      this._addTransaction(wellsFargoSecuredCard, restaurants, 'Sams', 22);

      // Paying secured card
      this._addTransaction(cashInWallet, wellsFargoSecuredCard, 'Card payment', 22);
    },

    _addTransaction: function _addTransaction(accountFrom, accountTo, description, amount) {
      var _this = this;

      // ToDo: Fix income, when it is an income it shouldnt be negative, same for equity!
      this.store.createRecord('transaction', {
        description: description,
        date: new Date()
      }).save().then(function (transaction) {
        _this.store.createRecord('split', {
          transaction: transaction,
          account: accountFrom,
          amount: -Math.abs(amount)
        }).save();

        _this.store.createRecord('split', {
          transaction: transaction,
          account: accountTo,
          amount: Math.abs(amount)
        }).save();
      });
    }
  });
});